<template>
 <b-field :label="label">
   <b-select v-model="model[field.key]"
    v-on:input="emitToParent"
    placeholder="Select a currency code">
     <option
      v-for="option in currencyCodes"
      :value="option.CurrencyCode"
      :key="option.CurrencyCode">
       {{ option.CurrencyName }} - {{ option.CurrencyCode }}
     </option>
   </b-select>
 </b-field>
</template>
<script>
import currencyCodes from '@/data/currencyCodes.json';

export default {
  name: 'currencyCodes',
  props: {
    value: String,
    title: String,
    form: Object,
    field: Object,
    model: Object,
    to: Object,
  },
  mounted() {
    this.currencyCodes = currencyCodes;
    this.currencyCodes.sort((t1, t2) => (t1.CurrencyName < t2.CurrencyName ? -1 : 1));
  },
  data() {
    return {
      currencyCodes: [],
      currencyCode: this.value,
      label: this.$props.field.templateOptions.wrapper.properties.label,
    };
  },
  methods: {
    emitToParent() {
      this.$emit('input', this.currencyCode);
    },
  },
};
</script>
